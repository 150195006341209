<template>
    <q-page>
        <router-view/>
    </q-page>
</template>


<script>

import { mapState } from 'vuex';
export default {
    name: "Quotazioni",
    data() {
        return {
        }
    },
    computed: {
        ...mapState({
            is_loading: state => state.gestionePratiche.is_loading
        })
    }
}
</script>

<style scoped lang="scss">


</style>


